<template>
    <div class="dashboard-main">
        <div>
            <div v-if="!isUserRoleRop" class="dashboard-main__container">
                <div class="box border border-gray-300 p-5"><strong>Всего судов: </strong> {{ info.shipsNumber }}</div>
                <div class="box border border-gray-300 p-5">
                    <strong>Всего круизов: </strong> {{ info.cruisesNumber }}
                </div>
                <div class="box border border-gray-300 p-5">
                    <strong>Всего заказов: </strong> {{ info.ordersNumber }}
                </div>
                <div class="box border border-gray-300 p-5">
                    <strong>Всего страниц: </strong> {{ info.pagesNumber }}
                </div>
            </div>

            <DashboardFilters />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DashboardFilters from '@/components/dashboard/DashboardFilters.vue';

export default {
    components: { DashboardFilters },
    data() {
        return {
            loading: false,
            info: {
                shipsNumber: 0,
                cruisesNumber: 0,
                ordersNumber: 0,
                pagesNumber: 0,
            },
            statistics: null,
        };
    },
    computed: {
        ...mapGetters({ isUserRoleRop: 'user/isUserRoleRop' }),
    },
    async created() {
        this.$store.commit('main/setPageHeader', 'Dashboard');

        this.loading = true;

        if (!this.isUserRoleRop) {
            await this.fetchStat();
        }

        this.loading = false;
    },
    methods: {
        async fetchStat() {
            try {
                const { data } = await this.axios.get('/info');

                if (data.success === true) {
                    this.info = { ...data.data };
                }
            } catch (ex) {
                console.log('cant fetch data: ', ex);
            }
        },
    },
};
</script>
<style scoped lang="scss">
.dashboard-main {
    &__container {
        @apply flex flex-wrap gap-6 mt-5;
    }

    &__column {
        flex: 1 1 20rem;
    }

    &__column-title {
        @apply flex items-center font-medium text-base px-5 py-3 text-gray-600 overflow-y-auto;
        max-height: 5rem;
        height: 100%;
    }

    &__empty-message {
        @apply px-5 py-3 bg-white rounded-lg drop-shadow-sm font-bold;
        text-align: center;
    }
}
</style>
